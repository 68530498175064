import { useCallback, useState } from 'react';
import styled from 'styled-components';

import { DetailedTimeRegistrationDTO, TimeRegistrationDTO, BaseWorkTaskTypeEnum } from '../../api/api';
import { getLocationString } from '../../utils/location/locationHandling';
import { StyledApprovedSpan } from './TimeRegTableTask';
import { TimeRegistrationSO } from '../../stateManagement/reducers/timeRegistrationReducer';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '../../components/table/Table';
import TimeRegMenu from './TimeRegMenu';
import InternalTimeRegForm from '../internal-timereg-form/InternalTimeRegForm';
import { useNavigateToTask } from '../../hooks/useNavigateToTask';
import Typography from '../../components/typography/Typography';

interface Props {
  timeRegistrations?: DetailedTimeRegistrationDTO[];
  handleDelete: (id: number) => void;
  handleEdit: (timeReg: TimeRegistrationSO) => void;
}

const TimeRegTableUser = (props: Props) => {
  const { timeRegistrations, handleDelete, handleEdit } = props;

  const [openInternalTimeReg, setOpenInternalTimeReg] = useState(false);
  const [internalTask, setInternalTask] = useState<DetailedTimeRegistrationDTO>();
  const navigateToTask = useNavigateToTask();

  const handleOnClickTask = useCallback(
    (task: DetailedTimeRegistrationDTO) => {
      if (!task.workTaskId) return;
      if (task && task.baseWorkTaskType === BaseWorkTaskTypeEnum.InternalWorkTask) {
        setOpenInternalTimeReg(true);
        setInternalTask(task);
      } else {
        navigateToTask(task.workTaskId, task.baseWorkTaskType);
      }
    },
    [navigateToTask]
  );

  return (
    <>
      {openInternalTimeReg && internalTask && (
        <InternalTimeRegForm
          taskId={internalTask.workTaskId}
          open={openInternalTimeReg}
          handleReturn={() => setOpenInternalTimeReg(false)}
          task={internalTask}
          disabled={true}
        />
      )}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Projekt</TableCell>
              <TableCell>Opgave</TableCell>
              <TableCell>Adresse</TableCell>
              <TableCell>Timeart</TableCell>
              <TableCell>Antal</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {timeRegistrations &&
              timeRegistrations.map((row, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <Typography>{row?.project?.id ?? '-'}</Typography>
                    <Typography>{row?.project?.name ?? ''}</Typography>
                  </TableCell>
                  <TableCell>
                    {row?.workTaskId ? (
                      <StyledLink onClick={() => handleOnClickTask(row)}>{row?.taskType ?? '-'}</StyledLink>
                    ) : (
                      <>{row?.taskType ?? '-'}</>
                    )}
                  </TableCell>
                  <TableCell>{row?.address ? getLocationString(row.address) : '-'}</TableCell>
                  <TableCell>{row?.category?.name ?? '-'}</TableCell>
                  <TableCell>{row.hours === 1 ? `${row.hours} time` : `${row.hours} timer`}</TableCell>
                  <TableCell>{renderApproved(row)}</TableCell>
                  <TableCell sx={{ padding: '0' }} align="right">
                    {row?.id && (
                      <TimeRegMenu
                        registration={row}
                        handleDelete={(id: number) => handleDelete(id)}
                        handleEdit={handleEdit}
                        isNegativeTime={row?.hours !== undefined ? row.hours < 0 : false}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const StyledLink = styled.a`
  color: ${(props) => props.theme.palette.functions.action.secondary};
  cursor: pointer;
`;

export const renderApproved = (row: TimeRegistrationDTO) => {
  return row.approvedByUser !== undefined ? (
    <StyledApprovedSpan approved={row.approvedByUser}>
      {row.approvedByUser ? 'Godkendt' : 'Ikke godkendt'}
    </StyledApprovedSpan>
  ) : (
    '-'
  );
};

export default TimeRegTableUser;
