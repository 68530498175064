import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  SelectProps as MUISelectProps,
  MenuItem as MuiMenuItem
} from '@mui/material';
import styled from 'styled-components';

export interface SelectProps<T> extends MUISelectProps<T> {
  compact?: boolean;
  marked?: boolean;
}

export const Select = <T extends unknown>(props: SelectProps<T>) => {
  const { label, required, fullWidth, compact, marked, ...rest } = props;

  return (
    <StyledFormControl fullWidth={fullWidth} style={{ maxWidth: '100%' }} marked={marked}>
      <StyledInputLabel required={required} compact={compact ?? false}>
        {label}
      </StyledInputLabel>
      <StyledMuiSelect label={label} compact={compact ?? false} {...rest} />
    </StyledFormControl>
  );
};

export const MenuItem = styled(MuiMenuItem)`
  &.Mui-selected {
    font-weight: 800;
  }
`;

const StyledInputLabel = styled(({ compact, ...props }) => <InputLabel {...props} />)<{
  compact: boolean;
}>`
  && {
    line-height: 1rem;
    top: ${(props) => (props.compact ? '-5px' : '')};
  }
`;

const StyledMuiSelect = styled(({ compact, marked, ...props }) => <MuiSelect {...props} />)<{
  compact: boolean;
  marked?: boolean;
}>`
  &.MuiOutlinedInput-root {
    border-radius: 8px;
    z-index: ${(props) => props.theme.zIndex.main};
  }

  .MuiSelect-select {
    padding: ${(props) => (props.compact ? '7px 16px' : '12px 16px')};
  }
`;

const StyledFormControl = styled(({ marked, ...props }) => <FormControl {...props} />)<{
  marked?: boolean;
}>`
  background-color: ${(props) => (props.marked ? props.theme.palette.functions.warning.tertiary : '')};
`;
export default Select;
