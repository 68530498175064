import { UpdateAnswerDTO } from '../api/api';
import WorkClient from '../api/workClient';

const updateAnswer = (answer: UpdateAnswerDTO) => {
  return WorkClient.updateAnswers([answer]);
};

const createRepeatableSectionInChecklist = (taskId: number, sectionId: number, workTaskChecklistId: number) => {
  return WorkClient.createRepeatableSectionForChecklist(taskId, sectionId, workTaskChecklistId);
};

const deleteRepetableSectionInTasklist = (taskSectionId: number) => {
  return WorkClient.deleteRepeatableSection(taskSectionId);
};

const validateChecklists = (taskId: number) => {
  return WorkClient.validateChecklistsAsync2(taskId);
};

const getChecklistsForWorkTask = (taskId: number) => {
  return WorkClient.checklists3(taskId);
};

const createChecklists = (workTaskId: number) => {
  return WorkClient.createChecklists(workTaskId);
};

const deleteFileAnswer = (workTaskId: number, fileAnswerId: number) => {
  return WorkClient.deleteFileAnswer(workTaskId, fileAnswerId);
};

const addChecklist = (workTaskId: number, checklistId: number) => {
  return WorkClient.createRepeatableChecklist(workTaskId, checklistId);
};

const removeChecklist = (workTaskChecklistId: number) => {
  return WorkClient.deleteRepeatableChecklist(workTaskChecklistId);
};

const setChecklistCompletionStatus = (isCompleted: boolean, workTaskChecklistId: number) => {
  return WorkClient.setChecklistCompletionStatus(isCompleted, [workTaskChecklistId]);
};

const setTaskSectionCompletionStatus = (isCompleted: boolean, taskSectionId: number) => {
  return WorkClient.setTaskSectionCompletionStatus(isCompleted, [taskSectionId]);
};

const TaskChecklistService = {
  updateAnswer,
  createRepeatableSectionInChecklist,
  deleteRepetableSectionInTasklist,
  getChecklistsForWorkTask,
  createChecklists,
  addChecklist,
  deleteFileAnswer,
  removeChecklist,
  validateChecklists,
  setChecklistCompletionStatus,
  setTaskSectionCompletionStatus
};

export default TaskChecklistService;
