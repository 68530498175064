import { FileParameter } from '../api/api';
import WorkClient from '../api/workClient';

const GetQAFile = (taskId: number, answerId: number) => {
  return WorkClient.qAGET(taskId, answerId);
};

const GetQAFile2 = (taskId: number, fileName: string, answerId: number) => {
  return WorkClient.fileGET(taskId, fileName, answerId);
};

const PostQAFiles = (taskId: number, answerId: number, files: FileParameter[]) => {
  return WorkClient.files(taskId, answerId, files);
};

const QAService = {
  GetQAFile,
  PostQAFiles,
  GetQAFile2
};

export default QAService;
